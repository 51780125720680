import { useEffect, useState } from "react";
import Script from "next/script";
import { useRouter } from "next/router";
import * as fbq from "../lib/fpixel";
import { CookiesProvider } from "react-cookie";

//redux setup
import { Provider } from "react-redux";
import store from "../redux/store/store";
import { loadUser } from "../redux/slices/auth";

import FingerprintJS from "@fingerprintjs/fingerprintjs";
import * as gtag from "../lib/gtag";
// import OneSignal from "react-onesignal";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { MathJaxContext, MathJax } from "better-react-mathjax";

import { browserName, browserVersion } from "react-device-detect";
import Banner from "../components/_App/Banner";

// import "../styles/bootstrap.min.css";
// import "../styles/animate.min.css";
// import "../styles/boxicons.min.css";
// import "../styles/meanmenu.min.css";
// import "../styles/flaticon.css";
import "../node_modules/react-modal-video/css/modal-video.min.css";
// import "react-image-lightbox/style.css";
import "../styles/style.scss";
import "../styles/style.css";
import "../styles/responsive.css";
//CKeditor stylesheet
import "../styles/content-styles.css";
import "../styles/pagination.scss";
// import "../components/course/TextLayer.css";

import Layout from "../components/_App/Layout";

import useAxios from "axios-hooks";
import dayjs from "dayjs";

import NextNProgress from "nextjs-progressbar";
import { NODE_ENV, WEB_API, BASE_URL } from "../constants";
import setAuthToken from "../utils/setAuthToken";

import randomize from "randomatic";
import ReactGA from "react-ga";
ReactGA.initialize(gtag.GA_TRACKING_ID);

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter();
  const [currentAffairs, setCurrentAffairs] = useState(null);
  const [data, setData] = useState(null);
  const [courseCategory, setCourseCategory] = useState(null);
  const [testSeriesCategory, setTestSeriesCategory] = useState(null);

  useEffect(() => {
    const fetchCurrentAffairs = async () => {
      const categoryRes = await fetch("/category.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const categoryData = await categoryRes.json();
      setData(categoryData);

      const offeringRes = await fetch("/offering.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const offeringData = await offeringRes.json();
      setCurrentAffairs(offeringData);

      const courseRes = await fetch("/course-category.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const courseData = await courseRes.json();
      setCourseCategory(courseData);

      const testSeriesRes = await fetch("/test-series-category.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const testSeriesData = await testSeriesRes.json();
      setTestSeriesCategory(testSeriesData);
    };
    fetchCurrentAffairs();
  }, []);

  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview();
    // fbq.event("ViewContent");

    const handleRouteChange = (url) => {
      fbq.pageview();
      ReactGA.pageview(window.location.pathname + window.location.search);
      // fbq.event("ViewContent");

      // gtag.pageview(url);
      // gtag.event("new Event", "Test category", "Test label", "Test value");
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    //!commented GTM Module
    if (!router.isReady) return;
    // const tagManagerArgs = {
    //   gtmId: "GTM-KD2WB2C",
    // };

    // TagManager.initialize(tagManagerArgs);

    //Initial FingerPrint Js

    // Initialize an agent at application startup.
    // const fpPromise = FingerprintJS.load({
    //   token: "p79jr6rYr4LMvF4JwgQr",
    //   region: "ap",
    // });
    const fpPromise = FingerprintJS.load();
    let diff = 0;
    const FPJS_UPDATED_ON = dayjs(localStorage.getItem("FPJS_UPDATED_ON"));
    const FPJS = localStorage.getItem("FPJS");
    let has_valid_FPJS_vars =
      localStorage.getItem("FPJS_UPDATED_ON") && FPJS ? true : false;

    const current_date = dayjs(new Date());
    diff = current_date.diff(FPJS_UPDATED_ON, "day", false);

    if (diff > 7 || !has_valid_FPJS_vars) {
      // fpPromise
      //   .then((fp) => fp.get())
      //   .then((result) => {
      //     localStorage.setItem("FPJS", JSON.stringify(result.visitorId));
      //     localStorage.setItem("FPJS_UPDATED_ON", new Date());
      //   });
      const randomID = randomize("a0", 24);
      localStorage.setItem("FPJS", randomID);
      localStorage.setItem("FPJS_UPDATED_ON", new Date());
    }
    // console.log("Browser id", localStorage.getItem("FPJS").replace(/"/g, ""));
  }, [router.isReady]);

  useEffect(() => {
    localStorage.removeItem("unique_order_id");
  }, []);

  useEffect(() => {
    const loadMe = async () => {
      if (typeof window !== "undefined" && localStorage.token) {
        await setAuthToken(localStorage.token);
        store.dispatch(loadUser());
      }
    };
    loadMe();
  }, []);

  // useEffect(() => {
  //   const enableOneSignal = async () => {
  //     await OneSignal.init({ appId: "59bae2ed-1392-433e-afcd-5aa0693dc8a4" });
  //     await OneSignal.showSlidedownPrompt();
  //     console.log("Onesignal initialized");
  //   };
  //   enableOneSignal();
  // }, []);

  useEffect(() => {
    const getData = async () => {
      const res = await axios.get("https://api.ipify.org/?format=json");
      localStorage.setItem("CLIENT_IP", res.data.ip);
      localStorage.setItem("CLIENT_AGENT", browserName + browserVersion);
    };
    getData();
  }, []);

  useEffect(() => {
    if (!router.isReady) return;

    const gclid = router.query.gclid;
    const fbclid = router.query.fbclid;
    const utm_source = router.query.utm_source;
    const utm_medium = router.query.utm_medium;
    const utm_campaign = router.query.utm_campaign;

    if (gclid) {
      localStorage.setItem("gclid", gclid);
    }
    if (fbclid) {
      localStorage.setItem("fbclid", fbclid);
    }
    if (utm_source) {
      localStorage.setItem("utm_source", utm_source);
    }
    if (utm_medium) {
      localStorage.setItem("utm_medium", utm_medium);
    }
    if (utm_campaign) {
      localStorage.setItem("utm_campaign", utm_campaign);
    }
  }, [router.isReady]);

  return (
    <CookiesProvider>
      <Provider store={store}>
        <MathJaxContext>
          <Layout>
            {/* Global Site Code Pixel - Facebook Pixel */}
            <link rel="stylesheet" type="text/css" href="#" />
            {/* <link rel="stylesheet" type="text/css" href="/static/css/nprogress.css" /> */}
            {/* <link
              href={`https://www.googletagmanager.com/gtag/js?id=${gtag.AW_TRACKING_ID}`}
              rel="preload"
              as="script"
            ></link>
            <link
              href={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
              rel="preload"
              as="script"
            ></link>
            <link
              href={`https://www.googletagmanager.com/gtag/js?id=${gtag.GTM_TRACKING_ID}`}
              rel="preload"
              as="script"
            ></link>
            
            <Script
              strategy="afterInteractive"
              src={`https://www.googletagmanager.com/gtag/js?id=${gtag.AW_TRACKING_ID}`}
            />
            <Script
              strategy="afterInteractive"
              src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
            />
            <Script
              strategy="afterInteractive"
              src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GTM_TRACKING_ID}`}
            /> */}
            {/* <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.AW_TRACKING_ID}',{'allow_enhanced_conversions':true});
            gtag('config', '${gtag.GA_TRACKING_ID}');
            gtag('config', '${gtag.GTM_TRACKING_ID}');
          `,
        }}
      /> */}
            {/* <Script
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-KJRHJH9');`,
              }}
            /> */}

            {/* <Script
        strategy="afterInteractive"
        // strategy="lazyOnload"
        id="_webengage_script_tag"
        dangerouslySetInnerHTML={{
          __html: `
          var webengage;!function(w,e,b,n,g){function o(e,t){e[t[t.length-1]]=function(){r.__queue.push([t.join("."),
          arguments])}}var i,s,r=w[b],z=" ",l="init options track screen onReady".split(z),a="feedback survey notification".split(z),c="options render clear abort".split(z),p="Open Close Submit Complete View Click".split(z),u="identify login logout setAttribute".split(z);if(!r||!r.__v){for(w[b]=r={__queue:[],__v:"6.0",user:{}},i=0;i < l.length;i++)o(r,[l[i]]);for(i=0;i < a.length;i++){for(r[a[i]]={},s=0;s < c.length;s++)o(r[a[i]],[a[i],c[s]]);for(s=0;s < p.length;s++)o(r[a[i]],[a[i],"on"+p[s]])}for(i=0;i < u.length;i++)o(r.user,["user",u[i]]);setTimeout(function(){var f=e.createElement("script"),d=e.getElementById("_webengage_script_tag");f.type="text/javascript",f.async=!0,f.src=("https:"==e.location.protocol?"https://widgets.in.webengage.com":"http://widgets.in.webengage.com")+"/js/webengage-min-v-6.0.js",d.parentNode.insertBefore(f,d)})}}(window,document,"webengage");webengage.init("in~311c480d");`,
        }}
      />{" "} */}
            <NextNProgress color="#5172ff" />
            <div id="modal"></div>
            {/* {currentAffairs && data && ( */}
            <Toaster />
            <Banner />
            <Component
              dataNav={{
                currentAffairs: currentAffairs,
                categories: data,
                courseCategory: courseCategory,
                testSeriesCategory: testSeriesCategory,
              }}
              {...pageProps}
            />
            {/* )} */}
          </Layout>
        </MathJaxContext>
      </Provider>
    </CookiesProvider>
  );
};

export default MyApp;
